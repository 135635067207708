
import { defineComponent, onMounted, ref } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import BaseService from "@/services/admin/BaseService";
import DuvidaForm from "@/components/admin/duvidas/DuvidaForm.vue";
import router from "@/router";
import Duvida from "@/interfaces/Duvida";
import store from "@/store";

export default defineComponent({
  components: { DuvidaForm },
  setup() {
    const $q = useQuasar();
    const loading = ref<boolean>(false);
    const duvidas = ref<Duvida[]>([]);
    const abertas = ref<Duvida[]>([]);
    const fechadas = ref<Duvida[]>([]);
    const andamentos = ref<Duvida[]>([]);
    const modalForm = ref();

    const openModalForm = (duvida: Duvida): void => {
      modalForm.value.open(duvida);
    };

    const goToAula = (aulaId: string, disciplinaId: string): void => {
      router.push({
        name: "ver-aula",
        params: { id: disciplinaId, aulaRouterId: aulaId },
      });
    };

    const getDuvidas = (): void => {
      loading.value = true;
      BaseService.list("professor/minhas-duvidas")
        .then((res) => {
          abertas.value = [];
          fechadas.value = [];
          andamentos.value = [];

          duvidas.value = res.data.filter((duvida: any) => {
            if (store.getters.divisaoId) {
              return duvida.aula.assunto.disciplina.divisao.id == store.getters.divisaoId ? true : false;
            }
            return true;
          });
          duvidas.value.forEach((duvida) => {
            if (duvida.status == "Aberta") {
              abertas.value.push(duvida);
            } else if (duvida.status == "Fechada") {
              fechadas.value.push(duvida);
            } else {
              andamentos.value.push(duvida);
            }
          });
        })
        .catch((err) => {
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getDuvidas();
    });

    return {
      loading,
      duvidas,
      abertas,
      fechadas,
      andamentos,
      getDuvidas,
      goToAula,
      showNotify,
      modalForm,
      openModalForm,
    };
  },
});
